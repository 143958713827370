<template>
    <svg
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 100 100"
    >
        <path
            d="M87.94 10.18 53.4 11.37h-.19c-.4.02-.75.27-.9.64-.15.37-.06.79.22 1.08l7.69 7.69-39.84 39.83c-.19.19-.29.44-.29.71s.11.52.29.71l.12.12c.39.39 1.02.39 1.41 0l40.67-40.67a.996.996 0 0 0 0-1.41l-6.61-6.62 30.81-1.06-1.06 30.81-6.61-6.61a.996.996 0 0 0-1.41 0L37.04 77.25a.996.996 0 0 0 0 1.41l.12.12c.19.19.44.29.71.29s.52-.11.71-.29l39.84-39.84 7.55 7.55.14.14c.28.28.7.37 1.08.23.37-.15.62-.5.64-.9l1.2-34.74c.01-.28-.1-.55-.29-.74a.93.93 0 0 0-.74-.29Z"
            class="cls-1"
        />
        <path
            d="m87.16 55.42-17.27.63c-.4.01-.75.26-.89.63-.15.37-.06.79.22 1.07l3.73 3.73-18.86 18.86c-.19.19-.29.44-.29.71s.11.52.29.71l7.76 7.76c.2.2.45.29.71.29s.51-.1.71-.29l18.86-18.86 3.73 3.73a.997.997 0 0 0 1.7-.67l.63-17.27c0-.28-.1-.55-.29-.74s-.47-.32-.74-.29Zm-1.51 15.94-2.81-2.81a.996.996 0 0 0-1.41 0L62.57 87.41l-6.35-6.35L75.08 62.2a.996.996 0 0 0 0-1.41l-2.81-2.81 13.9-.51-.51 13.9ZM19.78 47.04c.26 0 .51-.1.71-.29l18.86-18.86 3.73 3.73a.997.997 0 0 0 1.7-.67l.63-17.27c.01-.28-.1-.55-.29-.74s-.46-.31-.74-.29l-17.27.63c-.4.01-.75.26-.89.63-.15.37-.06.79.22 1.07l3.73 3.73-18.86 18.86c-.19.19-.29.44-.29.71s.11.52.29.71l7.76 7.76c.2.2.45.29.71.29Zm12.51-27.62a.996.996 0 0 0 0-1.41l-2.81-2.81 13.9-.51-.51 13.9-2.81-2.81c-.38-.38-1.04-.38-1.41 0L19.79 44.64l-6.35-6.35L32.3 19.43ZM17.02 65.65l-3.36 3.36a.996.996 0 0 0 0 1.41l.12.12c.19.19.44.29.71.29s.52-.11.71-.29l3.36-3.36a.996.996 0 0 0 0-1.41l-.12-.12c-.38-.38-1.04-.38-1.41 0ZM32 62.41l-.12-.12a.996.996 0 0 0-1.41 0L11.99 80.77a.996.996 0 0 0 0 1.41l.12.12c.2.2.45.29.71.29s.51-.1.71-.29l18.48-18.48a.996.996 0 0 0 0-1.41ZM33.56 70.69a.996.996 0 0 0-1.41 0l-6.72 6.72a.996.996 0 0 0 0 1.41l.12.12c.19.19.44.28.71.29.27 0 .52-.11.71-.29l6.72-6.72c.19-.19.29-.44.29-.71s-.11-.52-.29-.71l-.12-.12Z"
            class="cls-1"
        /></svg>
</template>
<style>
.cls-1 {
    @apply fill-current;
    stroke-width: 0;
}
</style>
